import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import * as ROUTES from "../../utils/routeNames.js";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames.js";
// import { FaEye, FaTrash } from "react-icons/fa";

function Category() {
  const [auth] = useAuth();
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [isCategoryLoading, setLoading] = useState(true);
  const [value, setValue] = useState(""); // For search input
  const [deleteCategoryId, setDeleteCategoryId] = useState(null); // For deleting category
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/category/allCategory?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setLoading(false);
      setCategoryNameList(res.data.categories);
      console.log("cxate", res.data.categories);

      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const openAddCategoryModal = () => {
    navigate(ROUTES.NEW_CATEGORY);
  };

  const handleSearchChange = (e) => {
    setValue(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  useEffect(() => {
    if (auth?.token) fetchCategories();
  }, [auth, currentPage, searchQuery]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between my-3">
            <div className="col-md-4">
              <h1 className="text-left">Categories</h1>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="form-group mb-0 flex-grow-1 mr-3">
                <div className="input-group input-group-md">
                  <input
                    type="search"
                    value={value}
                    onKeyDown={(e) => {
                      setCurrentPage(1);
                      if (e.key === "Enter") setSearchQuery(value);
                    }}
                    onChange={handleSearchChange}
                    className="form-control form-control-md"
                    placeholder="Search..."
                  />
                </div>
              </div>
              <button
                className="btn btn-success"
                onClick={openAddCategoryModal}
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row m-2">
          <div className="col-12">
            <div className="d-flex flex-wrap ">
              {!isCategoryLoading && categoryNameList?.length ? (
                categoryNameList.map((category) => (
                  <div
                    // onClick={() =>
                    //   navigate(`${ROUTES.VIEW_CATEGORY}/${category._id}`)
                    // }
                    key={category._id}
                    className="card m-3 shadow-sm"
                    style={{ width: "15rem" }}
                  >
                    {/* <img
                      src={`${BASE_URL}${category.category_image}`}
                      className="card-img-top"
                      // alt="Category"
                      style={{ height: "200px", objectFit: "contain" }}
                    /> */}
                    <div className="card-body ">
                      <h5>{category.category_name}</h5>
                      {/* <button
                        className="btn btn-primary m-1"
                        onClick={() => navigate(`${ROUTES.VIEW_CATEGORY}/${category._id}`)}
                      >
                        <FaEye /> View
                      </button>
                      <button
                        className="btn btn-danger m-1"
                        onClick={() => handleDelete(category._id)}
                      >
                        <FaTrash /> Delete
                      </button> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Data Found</p>
              )}
            </div>
          </div>
        </div>
        {/* Pagination */}
        <div className="pagination m-2 d-flex justify-content-center">
          <button
            className="btn btn-primary m-1"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={`page-${index + 1}`}
              onClick={() => setCurrentPage(index + 1)}
              className={`btn m-1 ${
                currentPage === index + 1 ? "bg-primary" : "btn-light"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="btn btn-primary m-1"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </section>
    </div>
  );
}

export default Category;
