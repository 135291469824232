export const BASE_URL = "https://admin.goparts.co.in/api";
// export const BASE_URL = "http://localhost:3000/api";
// const config = {
//     BASE_URL: "http://localhost:3000",
//   };

//   export const getBaseUrl = () => config.BASE_URL;

//   export const setBaseUrl = (newBaseUrl) => {
//     if (typeof newBaseUrl === "string" && newBaseUrl.trim() !== "") {
//       config.BASE_URL = newBaseUrl;
//       console.log(`BASE_URL updated to: ${config.BASE_URL}`);
//     } else {
//       console.error("Invalid URL. BASE_URL not updated.");
//     }
//   };

// Auth Routes
export const LOGIN = "/login";
export const REGISTER = "/register";

// Dashboard Routes
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const USER_DASHBOARD = "/user-dashboard";

// Admin Sub-Routes
export const HOME = `${ADMIN_DASHBOARD}/home`;
export const PROPOSALS = `${ADMIN_DASHBOARD}/proposal`;
export const CATEGORYS = `${ADMIN_DASHBOARD}/categories`;
export const NEW_PROPOSAL_TEMPLATE = `${ADMIN_DASHBOARD}/newproposaltemplete`;
export const PROPOSAL_TEMPLATES = `${ADMIN_DASHBOARD}/proposaltempletes`;
export const UPDATE_PROPOSAL_TEMPLATE = `${ADMIN_DASHBOARD}/updateproposaltemplete`;
export const VIEW_PROPOSAL_TEMPLATE = `${ADMIN_DASHBOARD}/viewproposaltemplete`;

// CUSTOMER Management
export const NEW_CUSTOMER = `${ADMIN_DASHBOARD}/newCustomer`;
export const ALL_CUSTOMERS = `${ADMIN_DASHBOARD}/customers`;
export const VIEW_CUSTOMER = `${ADMIN_DASHBOARD}/customerDetails/:id`;
export const UPDATE_CUSTOMER = `${ADMIN_DASHBOARD}/updateCustomerDetails`;

// Product Management
export const ALL_PRODUCTS = `${ADMIN_DASHBOARD}/products`;
export const NEW_PRODUCT = `${ADMIN_DASHBOARD}/newproduct`;
export const VIEW_PRODUCT = `${ADMIN_DASHBOARD}/viewproduct`;
export const UPADATE_PRODUCT = `${ADMIN_DASHBOARD}/updateproduct`;
//Order Management
export const ALL_ORDERS = `${ADMIN_DASHBOARD}/orders`;

export const VIEW_ORDER = `${ADMIN_DASHBOARD}/vieworder`;

// MOBILE Routes
export const ALL_MOBILES = `${ADMIN_DASHBOARD}/mobiles`;
export const NEW_MOBILE = `${ADMIN_DASHBOARD}/newMobile`;
export const NEW_MODELBYBRAND = `${ADMIN_DASHBOARD}/createModels`;
export const UPDATE_MOBILE = `${ADMIN_DASHBOARD}/updateMobile`;
//Category Routes

export const NEW_CATEGORY = `${ADMIN_DASHBOARD}/newcategory`;
export const VIEW_CATEGORY = `${ADMIN_DASHBOARD}/viewcategory`;

// Proposal Routes
export const ALL_PROPOSALS = `${ADMIN_DASHBOARD}/proposals`;
export const NEW_PROPOSAL = `${ADMIN_DASHBOARD}/newProposal`;
export const UPDATE_PROPOSAL = `${ADMIN_DASHBOARD}/updateProposal`;

// Coupon
export const ALL_COUPONS = `${ADMIN_DASHBOARD}/coupons`;
export const VIEW_COUPON = `${ADMIN_DASHBOARD}/viewcoupon`;
export const NEW_COUPON = `${ADMIN_DASHBOARD}/newcoupon`;

// Client Routes (if any other than dashboard can be added here)
// e.g. export const CLIENT_PROFILE = '/user-dashboard/profile';

// Fallback/NotFound Route
export const NO_ACCESS = "/no-access";
export const NOT_FOUND = "*";
