import React, { useEffect, useState } from "react";
import { useUserContext } from "../../context/UserContext.jsx";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { BASE_URL } from "../../utils/routeNames.js";
import "react-bootstrap-typeahead/css/Typeahead.css";

function UpdateProduct() {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [categories, setCategories] = useState([]);
  const [mobileVariants, setMobileVariants] = useState([]);
  const [selectedMobileVariants, setSelectedMobileVariants] = useState([]);

  // Fetch categories and product details
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/category/allCategory`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setCategories(
          res.data.categories.map((category) => ({
            id: category._id,
            label: category.category_name,
          }))
        );
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchProductDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/product/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setProduct(res.data);
        setSelectedMobileVariants(res.data.mobileVariant || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setError("Failed to load product details.");
        setLoading(false);
      }
    };

    const fetchMobileVariants = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/mobile/Variants`);
        setMobileVariants(
          res.data.map((variant) => ({
            id: variant._id,
            label: `${variant.modelId.brandId.name}, ${variant.modelId.name}, ${variant.ram} RAM, ${variant.storage}, ${variant.color}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching mobile variants:", error);
      }
    };

    fetchCategories();
    fetchProductDetails();
    fetchMobileVariants();
  }, [auth, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleMobileVariantChange = (selectedOption) => {
    setSelectedMobileVariants(selectedOption.map((option) => option.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = product.imageUrl;

      if (file) {
        const uploadData = new FormData();
        uploadData.append("image", file);

        const uploadResponse = await axios.post(
          `${BASE_URL}/upload/productImage`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        imageUrl = uploadResponse.data.imageUrl;
      }

      const updatedProductData = {
        ...product,
        imageUrl,
        mobile: selectedMobileVariants,
      };
      console.log("updatedProductData", updatedProductData);
      await axios.patch(
        `${BASE_URL}/product/${product._id}`,
        updatedProductData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      navigate(-1);
    } catch (error) {
      console.error("Error updating product:", error);
      setError("Failed to update product. Please try again.");
    }
  };

  const handleSelectChange = (selectedOption) => {
    setProduct((prev) => ({
      ...prev,
      category: selectedOption.length ? selectedOption[0].id : null,
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">Update Product</h3>
          </div>
          <form
            id="productForm"
            onSubmit={handleSubmit}
            className="form-horizontal"
          >
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="mobileVariant" className="control-label">
                  Mobile Variants
                </label>
                <Typeahead
                  id="mobileVariant"
                  onChange={handleMobileVariantChange}
                  options={mobileVariants}
                  selected={mobileVariants.filter((variant) =>
                    selectedMobileVariants.includes(variant.id)
                  )}
                  labelKey="label"
                  placeholder="Select Mobile Variants"
                  multiple
                />
                {/* <Typeahead
                  id="mobile-variants-typeahead"
                  options={models}
                  onChange={handleVariantChange}
                  placeholder="Select mobile "
                  multiple
                  selected={models.filter((model) =>
                    formData.mobile.some(
                      (mobile) => mobile.modelId === model.id
                    )
                  )}
                  clearButton
                /> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  {/* SKU */}
                  <div className="form-group">
                    <label htmlFor="sku" className="control-label">
                      SKU
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="sku"
                      name="sku"
                      readOnly={true}
                      value={product.sku}
                      onChange={handleChange}
                      placeholder="Enter SKU"
                      required
                    />
                  </div>

                  {/* Product Name */}
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={product.name}
                      onChange={handleChange}
                      placeholder="Enter Product Name"
                      required
                    />
                  </div>

                  {/* Description */}
                  <div className="form-group">
                    <label htmlFor="description" className="control-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={product.description}
                      onChange={handleChange}
                      rows="3"
                      placeholder="Enter Description"
                      required
                    />
                  </div>

                  {/* Currency */}
                  <div className="form-group">
                    <label htmlFor="currency" className="control-label">
                      Currency
                    </label>
                    <select
                      className="form-control"
                      id="currency"
                      name="currency"
                      value={product.currency}
                      onChange={handleChange}
                      required
                    >
                      <option value="₹">₹ - United States Dollar</option>
                      <option value="EUR">EUR - Euro</option>
                      <option value="GBP">GBP - British Pound</option>
                      <option value="INR">INR - Indian Rupee</option>
                      <option value="JPY">JPY - Japanese Yen</option>
                      <option value="CAD">CAD - Canadian Dollar</option>
                      <option value="AUD">AUD - Australian Dollar</option>
                      <option value="CNY">CNY - Chinese Yuan</option>
                      <option value="SGD">SGD - Singapore Dollar</option>
                      <option value="AED">AED - UAE Dirham</option>
                    </select>
                  </div>

                  {/* Purchase Price */}
                  <div className="form-group">
                    <label htmlFor="purchasePrice" className="control-label">
                      Purchase Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="purchasePrice"
                      name="purchasePrice"
                      value={product.purchasePrice}
                      onChange={handleChange}
                      min="0"
                      step="0.01"
                      placeholder="Enter Purchase Price"
                      required
                    />
                  </div>

                  {/* MRP */}
                  <div className="form-group">
                    <label htmlFor="mrp" className="control-label">
                      MRP
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mrp"
                      name="mrp"
                      value={product.mrp}
                      onChange={handleChange}
                      min="0"
                      step="0.01"
                      placeholder="Enter MRP"
                      required
                    />
                  </div>

                  {/* Commission */}
                  <div className="form-group">
                    <label htmlFor="commission" className="control-label">
                      Commission
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="commission"
                      name="commission"
                      value={product.commission || ""}
                      onChange={handleChange}
                      placeholder="Enter Commission"
                    />
                  </div>

                  {/* Commission Type */}
                  <div className="form-group">
                    <label htmlFor="commissionType" className="control-label">
                      Commission Type
                    </label>
                    <select
                      className="form-control"
                      id="commissionType"
                      name="commissionType"
                      value={product.commissionType || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Commission Type</option>
                      <option value="percentage">Percentage</option>
                      <option value="flat">Flat</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Sale Price */}
                  <div className="form-group">
                    <label htmlFor="salePrice" className="control-label">
                      Sale Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="salePrice"
                      name="salePrice"
                      value={product.salePrice}
                      onChange={handleChange}
                      min="0"
                      step="0.01"
                      placeholder="Enter Sale Price"
                      required
                    />
                  </div>

                  {/* Available Checkbox */}
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="isAvailable"
                        name="isAvailable"
                        checked={product.isAvailable}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="isAvailable">
                        Is Available
                      </label>
                    </div>
                  </div>

                  {/* Category */}
                  <div className="form-group">
                    <label htmlFor="category" className="control-label">
                      Category
                    </label>
                    <Typeahead
                      id="category"
                      onChange={handleSelectChange}
                      options={categories}
                      selected={categories.filter(
                        (category) => category.id === product.category
                      )}
                      labelKey="label"
                      placeholder="Select a Category"
                      required
                    />
                  </div>

                  {/* Product Image */}
                  <div className="form-group">
                    <label htmlFor="productImage" className="control-label">
                      Product Image
                    </label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="productImage"
                      name="image"
                      onChange={handleFileChange}
                    />
                    {preview && (
                      <div className="image-preview mt-2">
                        <img
                          src={preview}
                          alt="Preview"
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default UpdateProduct;
