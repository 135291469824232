import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useUserContext } from "../../context/UserContext.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Routes from "../../utils/routeNames";
import { BASE_URL } from "../../utils/routeNames.js";

function Products() {
  const [products, setProducts] = useState([]);
  const [auth] = useAuth();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(32);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const getProduct = async () => {
    try {
      const res = await axios.get(
        // search=iphone&pageNo=1&pageSize=12
        `${BASE_URL}/product/getProducts?pageNo=${pageNo}&pageSize=${pageSize}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      setProducts(res.data.products);
      setTotalProducts(res.data.totalProducts); // Assuming `totalCount` is the total number of products from API
      setTotalPages(res.data.totalPages); // Assuming `totalCount` is the total number of products from API
      console.log("Products", res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getProduct();
    }
  }, [auth, pageNo, searchQuery]);

  const handleView = (data) => {
    console.log("Product Details:", data);
    navigate(`${Routes.VIEW_PRODUCT}/${data._id}`);
  };

  const handleAddProduct = () => {
    navigate(Routes.NEW_PRODUCT);
  };

  const filteredProducts = products?.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastProduct = pageNo * pageSize;
  const indexOfFirstProduct = indexOfLastProduct - pageSize;

  const handleNextPage = () => {
    if (pageNo < Math.ceil(totalProducts / pageSize)) {
      setPageNo(pageNo + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between my-3">
            <div className="col-md-4">
              <h1 className="text-left ">Product Catalog</h1>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="form-group mb-0 flex-grow-1 mr-3">
                <div className="input-group input-group-md">
                  <input
                    type="search"
                    className="form-control form-control-md"
                    placeholder="Search by Product Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary btn-md"
                      type="button"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                onClick={handleAddProduct}
                className="btn btn-success ml-2"
              >
                <i className="fas fa-plus mr-1"></i> Add Product
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid ">
          <div class="card card-solid p-2">
            <div className="row">
              {filteredProducts?.length > 0 ? (
                filteredProducts.map((prod) => (
                  <div
                    className="col-md-3"
                    key={prod._id}
                    onClick={() => handleView(prod)}
                  >
                    <div className="card card-outline card-dark mb-4 shadow">
                      <div className="card-header p-0">
                        <img
                          src={`${BASE_URL.replace("/api", "")}${
                            prod.imageUrl
                          }`}
                          className="card-img-top rounded-top"
                          alt={prod.name}
                          style={{ height: "200px", objectFit: "contain" }}
                        />
                        <h5 className="card-title text-bold text-dark text-center m-2">
                          {prod.name}
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <span className="text-muted">Category:</span>
                          <span className="text-dark">
                            {prod?.category?.category_name || "N/A"}
                          </span>
                        </div>
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <span className="text-muted">Price:</span>
                          <span className="text-dark">
                            {prod.currency} {prod.salePrice}
                          </span>
                        </div>
                        {/* <div className="mb-2 d-flex justify-content-between align-items-center">
                          <span className="text-muted">SKU:</span>
                          <span className="text-dark">{prod.sku}</span>
                        </div> */}
                        {/* <div className=" mt-3 d-flex justify-content-between align-items-center">
                      <button className="btn btn-sm btn-success me-2">
                        View Details
                      </button>
                      <button className="btn btn-sm btn-warning">Edit</button>
                    </div> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>

            <div className="card-footer">
              <div className="d-flex align-items-center my-4">
                <button
                  className="btn btn-outline-dark"
                  onClick={handlePreviousPage}
                  disabled={pageNo === 1}
                >
                  <i className="fas fa-arrow-left mr-1"></i> Previous
                </button>

                {/* Display current page and total pages from the response */}
                <span className="m-2">
                  {pageNo} / {totalPages}
                </span>

                <button
                  className="btn btn-outline-dark"
                  onClick={handleNextPage}
                  disabled={pageNo === totalPages}
                >
                  Next <i className="fas fa-arrow-right ml-1"></i>
                </button>
              </div>

              {/* <nav aria-label="Page Navigation">
                <ul className="pagination justify-content-center m-0">
                  {/* Previous Button 
                  <li
                    className={`page-item ${
                      pageNo === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePreviousPage}
                      disabled={pageNo === 1}
                    >
                      <i className="fas fa-arrow-left"></i> Previous
                    </button>
                  </li>

                  {/* Page Numbers 
                  {Array.from(
                    { length: Math.ceil(totalProducts / pageSize) },
                    (_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${
                          pageNo === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          // onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    )
                  )}

                  {/* Next Button 
                    className={`page-item ${
                      pageNo === Math.ceil(totalProducts / pageSize)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNextPage}
                      disabled={
                        pageNo ===
                        Math.ceil(totalProducts / pageSize)
                      }
                    >
                      Next <i className="fas fa-arrow-right"></i>
                    </button>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
